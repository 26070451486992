import React from "react"
import Layout from "../components/layout"
import Section from "../components/Interface/Section"

export default () => (
  <Layout>
    <Section>
      <h1>Thank you!</h1>
      <p>
        We're looking forward to chatting with you. We'll be in touch as soon as
        possible!
      </p>
      <p>If you can't wait, you can try giving us a ring at 303-704-9171.</p>
    </Section>
  </Layout>
)
